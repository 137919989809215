const withdrawExploer = {
    'custody.withdrawExploer.searchCondition.Coin': '币种',

    'custody.withdrawExploer.searchCondition.Status': '提现状态',
    'custody.withdrawExploer.searchCondition.mainStatus': '提现主状态',
    'custody.withdrawExploer.searchCondition.subStatus': '提现子状态',
    'custody.withdrawExploer.searchCondition.failReason': '失败原因',
    'custody.withdrawExploer.searchCondition.Time': '提现申请时间',
    'custody.withdrawExploer.searchCondition.WithdrawalRequestID': '提现申请ID',
    'custody.withdrawExploer.searchCondition.Address': '提现地址',
    'custody.withdrawExploer.searchCondition.TransctionID': '链上交易ID',
    'custody.withdrawExploer.searchCondition.OrgName': '机构id/名称',
    'custody.withdrawExploer.search.btn.desc': '搜索',

    'custody.withdrawExploer.searchCondition.placeholder.coin': '默认选择全部币种',
    'custody.withdrawExploer.searchCondition.placeholder.WithdrawalRequestID': '客户请求ID',
    'custody.withdrawExploer.searchCondition.placeholder.Address': '接收地址',
    'custody.withdrawExploer.searchCondition.placeholder.TransctionID': 'Transaction ID',
    'custody.withdrawExploer.searchCondition.placeholder.OrgName': '默认选择全部',
    'custody.withdrawExploer.searchCondition.placeholder.Status': '默认选择待审批提现...',

    // 'custody.withdrawExploer.searchCondition.placeholder.Address': '',
    // 'custody.withdrawExploer.searchCondition.placeholder.Address': '',

    'custody.withdrawExploer.table.header.orgID': '机构ID',
    'custody.withdrawExploer.table.header.orgName': '机构名称',
    'custody.withdrawExploer.table.header.toAddress': '接收地址',
    'custody.withdrawExploer.table.header.fromAddress': '发送地址',
    'custody.withdrawExploer.table.header.coin': '币种',
    'custody.withdrawExploer.table.header.amount': '金额',
    'custody.withdrawExploer.table.header.WithdrawalType': '提现类型',
    'custody.withdrawExploer.table.header.Created': '申请日期',
    'custody.withdrawExploer.table.header.AuditResults': '审核结果',
    'custody.withdrawExploer.table.header.AuditBy': '审核人',
    'custody.withdrawExploer.table.header.actions': '操作',

    'custody.withdrawExploer.actions.note': '备注',
    'custody.withdrawExploer.actions.details': '详情',
    'custody.withdrawExploer.actions.Approve': '通过',
    'custody.withdrawExploer.actions.Reject': '拒绝',
    'custody.withdrawExploer.actions.Terminate': '废弃',
    'custody.withdrawExploer.actions.Reload': '刷新',

    'custody.withdrawExploer.detail.label.Amount': '提币金额',
    'custody.withdrawExploer.detail.label.To': '提现地址',
    'custody.withdrawExploer.detail.label.RequestID': '提现申请ID',
    'custody.withdrawExploer.detail.label.transactionStatus': 'Transaction 状态',
    'custody.withdrawExploer.detail.label.fiatCurrency': '法币金额',
    'custody.withdrawExploer.detail.label.Fee': '手续费',
    'custody.withdrawExploer.detail.label.TxId': '交易id',
    'custody.withdrawExploer.detail.label.walletId': 'wallet账号',
    'custody.withdrawExploer.detail.label.RiskInformation': '风控信息',
    'custody.withdrawExploer.detail.label.confirmationsNumber': '确认数',
    'custody.withdrawExploer.detail.label.successNumber': '当前地址成功提现次数',
    'custody.withdrawExploer.detail.label.1hourWithdrawals': '本币1小时内总出账',
    'custody.withdrawExploer.detail.label.1dayWithdrawals': '本币1天内总出账',
    'custody.withdrawExploer.detail.label.authClient': '是否为auth客户',
    'custody.withdrawExploer.detail.label.BindIp': '绑定ip',
    'custody.withdrawExploer.detail.label.Callback': '回调确认',
    'custody.withdrawExploer.detail.label.AssetInfo': '资产信息',

    'custody.withdrawExploer.detail.WithdrawalStatus.WithdrawalRequest': '发起',
    'custody.withdrawExploer.detail.WithdrawalStatus.ClientRC': '用户风控',
    'custody.withdrawExploer.detail.WithdrawalStatus.coboRC': 'Cobo风控',
    'custody.withdrawExploer.detail.WithdrawalStatus.BlockchainProcess': 'BC处理中',
    'custody.withdrawExploer.detail.WithdrawalStatus.custodyProcess': 'Custody处理中',
    'custody.withdrawExploer.detail.WithdrawalStatus.withdrawalResult': '提现结果',
    'custody.withdrawExploer.detail.WithdrawalStatus.minAgo': '分钟前',
    'custody.withdrawExploer.detail.retryCount': '重试次数',
    'custody.withdrawExploer.detail.broadcastMessage': '广播信息',

    'custody.withdrawExploer.actions.error': '提示信息',
    'custody.withdrawExploer.actions.success': '操作成功',
    'custody.withdrawExploer.fetchCoins.hints': '获取币种信息失败',

    'custody.withdrawExploer.modal.note.title': '您好， Cobo 檢測到您有1筆提現：',
    'custody.withdrawExploer.modal.note.createTime': '交易创建时间：',
    'custody.withdrawExploer.modal.note.requestID': '交易请求ID：',
    'custody.withdrawExploer.modal.note.number': '数量：',
    'custody.withdrawExploer.modal.note.address': '接收地址：',
    'custody.withdrawExploer.modal.note.confirm': '請確認是否可以放行？',

    // 'custody.env.select.lebal': '交易环境',
    // 'custody.env.select.walletProd': 'Wallet生产环境',
    // 'custody.env.select.walletSandbox': 'Wallet Sandbox',
    // 'custody.env.select.custodyProd': 'Custody生产环境',
    // 'custody.env.select.custodySandbox': 'Custody Sandbox',
    // 'custody.env.select.null': '未选择',
    // 'custody.withdrawExploer.searchCondition.Status.needOfflineCheck': '交易前置审核(-5)',
    // 'custody.withdrawExploer.searchCondition.Status.toBeMultiSigned': '多签等待中(-4)',
    // 'custody.withdrawExploer.searchCondition.Status.toBeApproved': '需Signer审核（请报给审核员 -3）',
    // 'custody.withdrawExploer.searchCondition.Status.newlyGenerated': '交易生成中(-2)',
    // 'custody.withdrawExploer.searchCondition.Status.toBeSigned': '等待签名(-1)',
    // 'custody.withdrawExploer.searchCondition.Status.toBeSent': '已签名待发送(0)',
    // 'custody.withdrawExploer.searchCondition.Status.alreadySent': '已发送至节点(1)',
    // 'custody.withdrawExploer.searchCondition.Status.inMemoryPool': '等待上链(2)',
    // 'custody.withdrawExploer.searchCondition.Status.pending': '确认中(3)',
    // 'custody.withdrawExploer.searchCondition.Status.confirmed': '已确认(4)',
    // 'custody.withdrawExploer.searchCondition.Status.failed': '失败(5)',

    // 'custody.withdrawExploer.searchCondition.Status.internaltx.needApprove': '待审核',
    // 'custody.withdrawExploer.searchCondition.Status.internaltx.newlyGenerated': '交易生成中',
    // 'custody.withdrawExploer.searchCondition.Status.internaltx.confirmed': '已完成',
    // 'custody.withdrawExploer.searchCondition.Status.internaltx.failed': '已失败',
    // 'custody.withdrawExploer.table.header.txID': '交易ID',
    // 'custody.withdrawExploer.table.header.coboID': 'Cobo ID',

    // 'custody.withdrawExploer.table.header.walletName': '钱包名称',
    // 'custody.withdrawExploer.table.header.orgUserName': '机构用户',
    // 'custody.withdrawExploer.table.header.userID': '用户ID',
    // 'custody.withdrawExploer.table.header.txFee': '交易费',
    // 'custody.withdrawExploer.table.header.delay': '停滞延时',
    // 'custody.withdrawExploer.table.header.actions.retry': '重试',
    // 'custody.withdrawExploer.table.header.actions.fail': '拒绝',
    // 'custody.withdrawExploer.table.header.actions.failRisk': '拒绝',
    // 'custody.withdrawExploer.table.body.empty': '暂无数据',

    // 'custody.withdrawExploer.detail.modal.tab.transactionInfo': '交易信息',
    // 'custody.withdrawExploer.detail.modal.tab.transactionOperation': '操作记录',
    // 'custody.withdrawExploer.detail.modal.tab.transactionOperation.retry': '交易重试记录',
    // 'custody.withdrawExploer.detail.modal.tab.transactionOperation.refuse': '交易拒绝记录',
    // 'custody.withdrawExploer.detail.modal.tab.transactionOperation.noRecord': '暂无记录',
    // 'custody.withdrawExploer.detail.modal.label.coboID': 'Cobo ID',
    // 'custody.withdrawExploer.detail.modal.label.txnID': '交易ID',
    // 'custody.withdrawExploer.detail.modal.label.userID': '用户ID',
    // 'custody.withdrawExploer.detail.modal.label.amount': '交易金额',
    // 'custody.withdrawExploer.detail.modal.label.to': '目标地址',
    // 'custody.withdrawExploer.detail.modal.label.txnFee': '交易费',
    // 'custody.withdrawExploer.detail.modal.label.confirmNumber': '确认数',
    // 'custody.withdrawExploer.detail.modal.label.memo': 'Memo',
    // 'custody.withdrawExploer.detail.modal.label.gasprice': 'Gas Price',
    // 'custody.withdrawExploer.detail.modal.label.sendTime': '发送时间',
    // 'custody.withdrawExploer.detail.modal.label.createdTime': '创建时间',
    // 'custody.withdrawExploer.detail.modal.label.status': '状态',

    // 'custody.withdrawExploer.retry.modal.hint.title': '确定拒绝这笔交易么？',
    // 'custody.withdrawExploer.retry.modal.hint.littleTitle': '交易取消后余额会返还给用户',
    // 'custody.withdrawExploer.retry.modal.return.balance.label': '待返还金额:',
    // 'custody.withdrawExploer.retry.modal.return.amount.label': '交易金额',
    // 'custody.withdrawExploer.retry.modal.return.txfee.label': '交易费',
    // 'custody.withdrawExploer.retry.modal.return.used.label': '应扣除费',
};
export default withdrawExploer;
