const pending_approval = {
    'transaction.approval.header.pending': 'Pending Approval',
    'transaction.approval.header.pending.changeToHistory': 'Go to History',
    'transaction.approval.header.history': 'History Approval',
    'transaction.approval.header.pending.changeToHistorPending': 'Back to Pending',
    'transaction.approval.header.pending.createNewTag': 'Create tag',
    'transaction.approval.table.title.userName': 'User Name',
    'transaction.approval.table.title.coboID': 'Cobo ID',
    'transaction.approval.table.title.fromAddress': 'From Address',
    'transaction.approval.table.title.isDiscussing': 'Discussing',
    'transaction.approval.table.title.toAddress': 'To Address',
    'transaction.approval.table.title.coin': 'Coin',
    'transaction.approval.table.title.amount': 'Amount',
    'transaction.approval.table.title.country': 'Country',
    'transaction.approval.table.title.txFee': 'Tx Fee',
    'transaction.approval.table.title.currency': 'Currency Amount',
    'transaction.approval.table.title.time': 'Time',
    'transaction.approval.table.title.opTime': 'Approval Time',
    'transaction.approval.table.title.actions': 'Actions',
    'transaction.approval.table.title.status': 'Status',
    'transaction.approval.table.title.operator': 'Operator',
    'transaction.approval.table.title.memo': 'Memo',
    'transaction.approval.table.title.OutboundID': 'OutboundID',
    'transaction.approval.table.title.internalTxID': 'InternalTx ID',
    'transaction.approval.table.showTxid': 'Show TxID',
    'transaction.approval.table.showMemo': 'Show Memo',

    'transaction.approval.search.btn.desc': 'Search',

    'transaction.approval.modal.btn.cancel': 'Cancel',
    'transaction.approval.modal.approve.btn.apply': 'Apply',
    'transaction.approval.modal.decline.header': 'Are you sure to decline this transaction?',
    'transaction.approval.modal.bulkdecline.header': 'Are you sure to Decline this {total} transaction?',
    'transaction.approval.modal.decline.header.warning': 'Decline when the user account is in risk',
    'transaction.approval.modal.decline.btn.decline': 'Decline',

    'transaction.approval.modal.approve.header': 'Are you sure to approve this transaction?',
    'transaction.approval.modal.bulkapprove.header': 'Are you sure to approve this {total} transaction?',
    'transaction.approval.modal.approve.header.warning':
        'Make sure the user account is in secure, this is withdraw from real user',
    'transaction.approval.modal.approve.allTransactions':
        'I confirm the user plan to have several similar Tx today, approve all subsequent Tx for the user today',
    'transaction.approval.modal.approve.btn.approve': 'Approve',
    'transaction.approval.modal.approve.btn.declined': 'Declined',
    'transaction.approval.modal.approve.btn.showTransaction': 'View',
    'transaction.approval.modal.approve.btn.bulkapprove': 'bulk Approve',
    'transaction.approval.modal.approve.btn.bulkdeclined': 'bulk Declined',
};
export default pending_approval;
