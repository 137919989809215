const withdrawExplorerReborn = {
    'custody.withdrawExplorerReborn.tableHeader.request_id': '提现申请 ID',
    'custody.withdrawExplorerReborn.tableHeader.org_id': '机构 ID',
    'custody.withdrawExplorerReborn.tableHeader.org_name': '机构名称',
    'custody.withdrawExplorerReborn.tableHeader.to': '目的地址',
    'custody.withdrawExplorerReborn.tableHeader.amount': '交易金额',
    'custody.withdrawExplorerReborn.tableHeader.status': '主状态',
    'custody.withdrawExplorerReborn.tableHeader.sub_status': '子状态',
    'custody.withdrawExplorerReborn.tableHeader.type': '种类',
    'custody.withdrawExplorerReborn.tableHeader.source_type': '来源',
    'custody.withdrawExplorerReborn.tableHeader.initiator_type': '发起者',
    'custody.withdrawExplorerReborn.tableHeader.created_timestamp': '创建时间',
    'custody.withdrawExplorerReborn.tableHeader.risk_message': '风险信息',
    'custody.withdrawExplorerReborn.tableHeader.audit_results': '审查结果',
    'custody.withdrawExplorerReborn.tableHeader.audited_by': '审查操作人',
    'custody.withdrawExplorerReborn.tableHeader.actions': '操作',

    'custody.withdrawExplorerReborn.filterLabel.type': '种类',
    'custody.withdrawExplorerReborn.filterLabel.time_range': '创建时间',
    'custody.withdrawExplorerReborn.filterLabel.org_id': '机构ID/名称',
    'custody.withdrawExplorerReborn.filterLabel.token_ids': '货币 ID',
    'custody.withdrawExplorerReborn.filterLabel.request_ids': '提现申请 ID',
    'custody.withdrawExplorerReborn.filterLabel.transaction_hashes': '链上交易 ID',
    'custody.withdrawExplorerReborn.filterLabel.cobo_ids': 'Cobo ID',
    'custody.withdrawExplorerReborn.filterLabel.transaction_ids': '交易 ID',
    'custody.withdrawExplorerReborn.filterLabel.to_addresses': '目的地址',
    'custody.withdrawExplorerReborn.filterLabel.status': '主状态',
    'custody.withdrawExplorerReborn.filterLabel.sub_status': '子状态',

    'custody.withdrawExplorerReborn.filterPlaceholder.type': '全部种类',
    'custody.withdrawExplorerReborn.filterPlaceholder.time_range.start': '起始时间...',
    'custody.withdrawExplorerReborn.filterPlaceholder.time_range.end': '结束时间...',
    'custody.withdrawExplorerReborn.filterPlaceholder.org_id': '全部机构',
    'custody.withdrawExplorerReborn.filterPlaceholder.token_ids': '全部货币',
    'custody.withdrawExplorerReborn.filterPlaceholder.request_ids': '请求ID...',
    'custody.withdrawExplorerReborn.filterPlaceholder.transaction_hashes': '链上交易ID...',
    'custody.withdrawExplorerReborn.filterPlaceholder.cobo_ids': 'Cobo ID...',
    'custody.withdrawExplorerReborn.filterPlaceholder.transaction_ids': '交易ID',
    'custody.withdrawExplorerReborn.filterPlaceholder.to_addresses': '目的地址...',
    'custody.withdrawExplorerReborn.filterPlaceholder.status': '全部主状态',
    'custody.withdrawExplorerReborn.filterPlaceholder.sub_status': '全部子状态',

    'custody.withdrawExplorerReborn.detail.title': 'Portal交易详情 ({transaction_id})',
    'custody.withdrawExplorerReborn.detail.txDetail.title': '交易信息',
    'custody.withdrawExplorerReborn.detail.txDetail.txAmount': '金额',
    'custody.withdrawExplorerReborn.detail.txDetail.fee': '手续费',
    'custody.withdrawExplorerReborn.detail.txDetail.type': '种类',
    'custody.withdrawExplorerReborn.detail.txDetail.requestId': '请求ID',
    'custody.withdrawExplorerReborn.detail.txDetail.status': '主状态',
    'custody.withdrawExplorerReborn.detail.txDetail.txHash': '链上交易ID',
    'custody.withdrawExplorerReborn.detail.txDetail.description': '描述',
    'custody.withdrawExplorerReborn.detail.txDetail.isLoop': '是否为Loop',
    'custody.withdrawExplorerReborn.detail.txDetail.fromAddr': '来源地址',
    'custody.withdrawExplorerReborn.detail.txDetail.nonce': 'Nonce',
    'custody.withdrawExplorerReborn.detail.txDetail.source': '来源',
    'custody.withdrawExplorerReborn.detail.txDetail.coboId': 'Cobo ID',
    'custody.withdrawExplorerReborn.detail.txDetail.subStatus': '子状态',
    'custody.withdrawExplorerReborn.detail.txDetail.confirmedNumber': '确认数',
    'custody.withdrawExplorerReborn.detail.txDetail.brc20': 'BRC20',
    'custody.withdrawExplorerReborn.detail.txDetail.toAddr': '目的地址',
    'custody.withdrawExplorerReborn.detail.txDetail.fiatValue': '法币价值',
    'custody.withdrawExplorerReborn.detail.txDetail.initiator': '发起者',
    'custody.withdrawExplorerReborn.detail.txDetail.txId': '交易ID',
    'custody.withdrawExplorerReborn.detail.txDetail.toAddrDetail': '目的地址详情',
    'custody.withdrawExplorerReborn.detail.txDetail.toAddrDetail.toAddress': '目的地址',
    'custody.withdrawExplorerReborn.detail.txDetail.toAddrDetail.amount': '金额',
    'custody.withdrawExplorerReborn.detail.txDetail.toAddrDetail.script': '脚本',
    'custody.withdrawExplorerReborn.detail.txDetail.toAddrDetail.one': '{value}条记录',
    'custody.withdrawExplorerReborn.detail.txDetail.toAddrDetail.other': '{value}条记录',
    'custody.withdrawExplorerReborn.detail.txDetail.fundPoolId': '资金池ID',

    'custody.withdrawExplorerReborn.detail.txProcess.title': '交易流程',
    'custody.withdrawExplorerReborn.detail.txProcess.startWidget.initiator.title': '发起信息',
    'custody.withdrawExplorerReborn.detail.txProcess.startWidget.initiatorType': '发起者类型：{initiator_type}',
    'custody.withdrawExplorerReborn.detail.txProcess.startWidget.initiator': '发起者：{initiator}',
    'custody.withdrawExplorerReborn.detail.txProcess.startWidget.createdAt': '创建时间：{created_at}',
    'custody.withdrawExplorerReborn.detail.txProcess.startWidget.updatedAt': '更新时间：{updated_at}',

    'custody.withdrawExplorerReborn.detail.txProcess.customerWidget.title': '用户风控',
    'custody.withdrawExplorerReborn.detail.txProcess.customerWidget.inProgress': '(仍在进行中)',
    'custody.withdrawExplorerReborn.detail.txProcess.customerWidget.spenderCheck': '提币发起 ({current}/{total})：',
    'custody.withdrawExplorerReborn.detail.txProcess.customerWidget.approverCheck': '提币审核 ({current}/{total})：',

    'custody.withdrawExplorerReborn.detail.txProcess.coboWidget.title': 'Cobo风控',
    'custody.withdrawExplorerReborn.detail.txProcess.coboWidget.approved': '审核通过',
    'custody.withdrawExplorerReborn.detail.txProcess.coboWidget.rejected': '审核拒绝',
    'custody.withdrawExplorerReborn.detail.txProcess.coboWidget.proof': '证明已上传',
    'custody.withdrawExplorerReborn.detail.txProcess.coboWidget.approvalNode': '审核节点',

    'custody.withdrawExplorerReborn.hugeApproveModal.clientAuditRecord': '客户审核记录',
    'custody.withdrawExplorerReborn.hugeApproveModal.spenderCheck': '提币发起',
    'custody.withdrawExplorerReborn.hugeApproveModal.audit': '审核',

    'custody.withdrawExplorerReborn.detail.txProcess.bcWidget.blockchainProcess.title': 'Blockchain 处理进度',
    'custody.withdrawExplorerReborn.detail.txProcess.bcWidget.replacedBy.title': '交易已被替换',
    'custody.withdrawExplorerReborn.detail.txProcess.bcWidget.replacedByType': '被替换类型：{replaced_by_type}',
    'custody.withdrawExplorerReborn.detail.txProcess.bcWidget.replacedByTransactionId':
        '被替换交易ID：{replaced_by_transaction_id}',
    'custody.withdrawExplorerReborn.detail.txProcess.bcWidget.replacedByTransactionHash':
        '被替换交易哈希：{replaced_by_transaction_hash}',
    'custody.withdrawExplorerReborn.detail.txProcess.bcWidget.replaced.title': '已替换其他交易',
    'custody.withdrawExplorerReborn.detail.txProcess.bcWidget.replacedType': '替换类型：{replaced_type}',
    'custody.withdrawExplorerReborn.detail.txProcess.bcWidget.replacedTransactionId':
        '替换交易ID：{replaced_transaction_id}',
    'custody.withdrawExplorerReborn.detail.txProcess.bcWidget.replacedTransactionHash':
        '替换交易哈希：{replaced_transaction_hash}',
    'custody.withdrawExplorerReborn.detail.txProcess.resultWidget.status.title': '交易状态',
    'custody.withdrawExplorerReborn.detail.txProcess.resultWidget.status': '主状态: ',
    'custody.withdrawExplorerReborn.detail.txProcess.resultWidget.subStatus': '子状态: ',
    'custody.withdrawExplorerReborn.detail.txProcess.resultWidget.timeline.title': '交易时间线',
    'custody.withdrawExplorerReborn.detail.txProcess.resultWidget.timeline.wip': '(进行中)',

    'custody.withdrawExplorerReborn.detail.txRiskData.title': '风险信息',
    'custody.withdrawExplorerReborn.detail.txRiskData.withdrawSuccessCount': '接收地址成功提现次数',
    'custody.withdrawExplorerReborn.detail.txRiskData.oneHourWithdraw': '本币1小时内提币金额',
    'custody.withdrawExplorerReborn.detail.txRiskData.oneDayWithdraw': '本币24小时内提币金额',
    'custody.withdrawExplorerReborn.detail.txRiskData.oneHourWithdrawCount': '本币1小时内提币次数',
    'custody.withdrawExplorerReborn.detail.txRiskData.oneDayWithdrawCount': '本币24小时内提币次数',

    'custody.withdrawExplorerReborn.detail.txOrgDetail.title': '机构详情',
    'custody.withdrawExplorerReborn.detail.txOrgDetail.orgId': '机构ID',
    'custody.withdrawExplorerReborn.detail.txOrgDetail.orgName': '机构名称',
    'custody.withdrawExplorerReborn.detail.txOrgDetail.isIpBound': '绑定IP',
    'custody.withdrawExplorerReborn.detail.txOrgDetail.isCallbackConfirmed': '回调确认',

    'custody.withdrawExplorerReborn.hugeApproveModal.upload': '等待上传证明',
    'custody.withdrawExplorerReborn.hugeApproveModal.placeholder': '上传证明，最大500字符',
    'custody.withdrawExplorerReborn.aproveModal.placeholder': '此操作有风险，建议填写备注信息',
    'custody.withdrawExplorerReborn.terminateModal.title': '确定终止这笔交易吗？',
    'custody.withdrawExplorerReborn.terminateModal.note': '交易取消后余额会返还给用户',
    'custody.withdrawExplorerReborn.terminateModal.refundTitle': '待返还金额',
    'custody.withdrawExplorerReborn.terminateModal.refundModal.title': '确认待返还金额',
    'custody.withdrawExplorerReborn.terminateModal.form.refundAmount': '交易金额',
    'custody.withdrawExplorerReborn.terminateModal.form.refundFee': '交易费',
    'custody.withdrawExplorerReborn.terminateModal.form.force': '强制操作（该操作有一定风险，谨慎操作）',
};

export default withdrawExplorerReborn;
